<template>
  <div class="scroll-container" :style="{'font-size': fontSize + 'px'}" ref="scrollContainer">
    {{ currentNumber }}
  </div>
</template>

<script>
export default {
  props: {
    fontSize: {
      type: Number,
      default: 0
    },

    maxNumber: {
      type: String,
      default: 0
    }
  },
  data() {
    return {
      currentNumber: 0,
      totalDuration: 2000, // 整体动画时长，单位毫秒
      observer: null,
      animationStarted: false
    };
  },
  methods: {
    startAnimation() {
      const startTime = Date.now();
      const updateNumber = () => {
        const elapsedTime = Date.now() - startTime;
        const progress = elapsedTime / this.totalDuration;
        this.currentNumber = Math.min(Math.floor(this.maxNumber * progress), this.maxNumber);
        if (this.currentNumber < this.maxNumber) {
          requestAnimationFrame(updateNumber);
        }
      };
      requestAnimationFrame(updateNumber);
    }
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !this.animationStarted) {
          this.startAnimation();
          this.animationStarted = true;
          this.observer.unobserve(this.$refs.scrollContainer);
        }
      });
    }, {
      root: null,  // 相对于视口检测
      threshold: 0.1  // 10%的元素可见时触发
    });

    this.observer.observe(this.$refs.scrollContainer);
  }
};
</script>

<style>
.scroll-container {
  text-align: center;
}
</style>
