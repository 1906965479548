<template>
  <div class="full-page">
    <div class="header">
      <img class="logo" src="@/assets/imgs/website/logo.png" />
      <div class="contact">
        <img src="@/assets/imgs/website/nav-phone.png" alt="">
        <span class="text1">全国招商热线</span>
        <span class="text2">400-622-0010</span>
      </div>
    </div>
    <div class="banner">
      <img
        :style="{ height: imgSuccess ? 'auto' : '912px' }"
        class="banner-img"
        src="@/assets/imgs/website/banner.png"
        @load="onImageLoad"
      />
    </div>
    <div class="box-1">
      <div class="box-1-title">
        <div class="text-1">留下手机号，免费领取全套产品资料</div>
        <div class="text-2">
          (已有<div class="active"><scrollContainer maxNumber="1722" :fontSize="60"></scrollContainer>人</div>领取行业资料)
        </div>
      </div>
      <div class="form">
        <div class="form-item">
          <div class="form-item-label">姓名</div>
          <div class="form-item-value">
            <input type="text" v-model="postForm.name" placeholder="请输入您的姓名" />
          </div>
        </div>
        <div class="form-item">
          <div class="form-item-label">电话</div>
          <div class="form-item-value">
            <input type="text" v-model="postForm.phone" placeholder="请输入手机号码" />
          </div>
        </div>
      </div>
      <button class="submit-btn" @click="sendEmail">立即领取</button>
      <div class="bottom-list">
        <div class="bottom-item" v-for="(item, index) in box1List" :key="index">
          <img :src="require(`@/assets/imgs/website/box1-${index + 1}.png`)" />
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
    <div class="box-2">
      <img class="box-2-bg" src="@/assets/imgs/website/box-2-bg.png" />
      <div class="box-2-content">
        <div class="box-2-title">选对品牌，选对团队，才能走向成功</div>
        <div class="box-2-remark">智能充电桩基础设施制造商与运营商，已成功助力数千合伙人创业赚收</div>
        <div class="box-2-list">
          <div class="box-2-list-item" v-for="(item, index) in box2List" :key="index">
            <img :src="require(`@/assets/imgs/website/box2-${index + 1}.png`)" />
            <div class="text1"><scrollContainer :maxNumber="item.number" :fontSize="44"></scrollContainer><span class="unit">{{ item.unit }}</span></div>
            <div class="text2">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-3">
      <div class="box-3-left">
        <div class="box-3-title">
          <div class="title-1">新升级</div>
          <div class="title-2">8重保护 更安心</div>
        </div>
        <div class="left-container">
          <div class="container-item" v-for="(item, index) in box3ListA" :key="index">
            <div class="container-item-title">
              <div class="title-line"></div>
              <span class="title-text">{{ item.title }}</span>
            </div>
            <div class="container-item-desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
      <div class="box-3-right">
        <div class="box-3-title">
          <div class="title-1">新创新</div>
          <div class="title-2">6大智慧功能 更省心</div>
        </div>
        <div class="right-container">
          <div class="container-item" v-for="(item, index) in box3ListB" :key="index">
            <img class="container-item-icon" :src="require(`@/assets/imgs/website/box3-${index+1}.png`)">
            <div class="container-item-content">
              <div class="container-item-title">{{ item.title }}</div>
              <div class="container-item-desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-4">
      <div class="box-4-left">
        <img
          v-for="(item, index) in 3"
          class="box-4-left-img"
          :src="require(`@/assets/imgs/website/box4-${index + 1}.png`)"
        />
      </div>
      <div class="box-4-right">
        <div class="vs-box">
          <img class="vs-img" src="@/assets/imgs/website/vs.png" />
        </div>
        <div class="right-box-1">
          <div class="right-box-title">循电充电桩</div>
          <div class="right-box-container">
            <div class="container-item" v-for="(item, index) in box4ListA" :key="index" >
              <img class="container-item-icon" src="@/assets/imgs/website/pass.png" />
              <div class="container-item-content">
                <div class="container-item-title">{{ item.title }}</div>
                <div class="container-item-desc">{{ item.desc }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-box-2">
          <div class="right-box-title">其它充电桩</div>
          <div class="right-box-container">
            <div class="container-item" v-for="(item, index) in box4ListB" :key="index" >
              <img class="container-item-icon" src="@/assets/imgs/website/no-pass.png" />
              <div class="container-item-content">
                <div class="container-item-title">{{ item.title }}</div>
                <div class="container-item-desc">{{ item.desc }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-5">
      <div class="box-5-title">投入与回报案例分析</div>
      <div class="box-5-container">
        <div class="box-5-left">
          <div class="left-title">实际运营数据</div>
          <div class="left-container">
            <div class="left-content-item" v-for="(item, index) in box5List" :key="index">
              <div class="item-title">{{ item.title }}</div>
              <div class="data-point">
                <div class="data-point-item" v-for="(pointItem, pointIndex) in item.dataPoints" :key="pointIndex">
                  <div class="data-point-value" :class="pointItem.active ? 'active' : ''">
                    <span class="num">{{ pointItem.num }}</span>
                    <span class="unit">{{ pointItem.unit }}</span>
                  </div>
                  <div class="data-point-name">{{ pointItem.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-5-right">
          <img src="@/assets/imgs/website/box-5.png">
        </div>
      </div>
    </div>
    <div class="box-6">
      <div class="box-6-title">加入我们，你将获得</div>
      <img class="box-6-img" src="@/assets/imgs/website/box-6.png">
    </div>
    <div class="box-7">
      <div class="box-7-title">成功案例</div>
      <div class="box-7-list">
        <div class="box-7-item" v-for="(item, index) in box6List" :key="index">
          <img class="box-7-item-img" :src="require(`@/assets/imgs/website/box7-${index + 1}.png`)" />
          <div class="box-7-item-name">{{ item.title }}</div>
          <div class="box-7-item-point">
            <div class="box-7-item-point-item" v-for="(pointItem, pointIndex) in item.dataPoints" :key="pointIndex">
              <div class="point-item-name">{{ pointItem.name }}</div>
              <div class="point-item-value">
                <span class="num">{{ pointItem.num }}</span>
                <span class="unit">{{ pointItem.unit }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-1">
      <div class="box-1-title">
        <div class="text-1">留下手机号，免费领取全套产品资料</div>
        <div class="text-3">(已有1722人领取行业资料)</div>
      </div>
      <div class="form">
        <div class="form-item">
          <div class="form-item-label">姓名</div>
          <div class="form-item-value">
            <input type="text" v-model="postForm.name" placeholder="请输入您的姓名" />
          </div>
        </div>
        <div class="form-item">
          <div class="form-item-label">电话</div>
          <div class="form-item-value">
            <input type="text" v-model="postForm.phone" placeholder="请输入手机号码" />
          </div>
        </div>
      </div>
      <button class="submit-btn" @click="sendEmail">立即领取</button>
    </div>
    <div class="footer">
      <div class="footer-top">
        <div class="footer-item" v-for="(item, index) in footerList" :key="index">
          <img class="footer-item-icon" :src="require(`@/assets/imgs/website/footer-${index + 1}.png`)" >
          <div class="footer-item-content">
            <div class="footer-item-title">{{ item.title }}</div>
            <div class="footer-item-desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="footer-bottom-text1">Copyright © 广东循电科技有限公司 地址：广州市黄埔区科学大道112号1501室 <a target="_blank" style="color: inherit;" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2023096486号-2</a></div>
        <div class="footer-bottom-text2">温馨提示： 投资有风险，选择需谨慎</div>
      </div>
    </div>

    <div class="sidebar">
      <div class="sidebar-item wechat">
        <img class="sidebar-item-icon" src="@/assets/imgs/website/we-chat.png" />
        <img class="sidebar-item-icon-active" src="@/assets/imgs/website/we-chat-active.png" />
        <div class="sidebar-item-text">微信咨询</div>
        
        <div class="sidebar-item-popup">
          <img class="qrcode" src="@/assets/imgs/website/wechat-qrcode.png" />
        </div>
      </div>
      <div class="sidebar-item phone" style="display: block">
        <img class="sidebar-item-icon" src="@/assets/imgs/website/phone.png" />
        <img class="sidebar-item-icon-active" src="@/assets/imgs/website/phone-active.png" />
        <div class="sidebar-item-text">电话咨询</div>
        
        <div class="sidebar-item-popup">
          <img class="qrcode" src="@/assets/imgs/website/phone-bg.png" />
          <div class="item-popup-container">
            <div class="item-popup-number">400-622-0010</div>
            <div class="item-popup-text">全国咨询热线</div>
          </div>
        </div>
      </div>
      <div class="sidebar-item about-us">
        <img class="sidebar-item-icon" src="@/assets/imgs/website/about-us.png" />
        <img class="sidebar-item-icon-active" src="@/assets/imgs/website/about-us-active.png" />
        <div class="sidebar-item-text">关注我们</div>

        <div class="sidebar-item-popup">
          <img class="qrcode" src="@/assets/imgs/website/about-us-qrcode.png" />
        </div>
      </div>
      <div class="sidebar-item" @click="scrollToTop">
        <img class="sidebar-item-icon" src="@/assets/imgs/website/return-top.png" />
        <div class="sidebar-item-text">回到顶部</div>
      </div>
    </div>
  </div>
</template>

<script>
import scrollContainer from '@/components/scroll-container'
export default {
  components: {
    scrollContainer
  },
  
  data() {
    return {
      imgSuccess: false,
      box1List: [
        { title: '产品介绍' },
        { title: '充电站效果图' },
        { title: '成本与收益分析' },
        { title: '扶持与奖励政策' },
        { title: '运营管理方法' }
      ],
      box2List: [
        { name: '上线设备', unit: '万+', number: '10'  },
        { name: '服务小区', unit: '+', number: '1000'  },
        { name: '充电服务数', unit: '万+', number: '1000'  },
        { name: '合伙人', unit: '+', number: '1000'  },
        { name: '覆盖城市', unit: '+', number: '100'  },
      ],
      box3ListA: [
        { title: '故障自检', desc: '可自动检测设备当前故障，对故障进行修复' },
        { title: '高温保护', desc: '如遇炎热天气引发充电线路高温异常，能自动断电有效避免失火风险' },
        { title: '阻燃保护', desc: '阻燃等级达到UL94-VO级，30s内自动熄灭火焰' },
        { title: '防雷保护', desc: '内设防雷装置， 无惧雷雨等恶劣天气' },
        { title: '漏电保护', desc: '如遇漏电情况可在0.1S内自动切断电源，保护人身安全' },
        { title: '防雨防尘保护', desc: '主机箱防雨防尘等级IP54，充电口均配备防雨罩'},
        { title: '短路保护', desc: '发生短路时，系统自动切断充电回路' },
        { title: '过载保护', desc: '最大功率100-2000可用，过载自动断电'},
      ],
      box3ListB: [
        { title: '智能插入检测', desc: '无需选择充电口，刷卡即用，简化刷卡充电步骤' },
        { title: '智能语音提示', desc: '' },
        { title: '空载断电', desc: '' },
        { title: '自动识别充电功率', desc: '' },
        { title: '充满自停', desc: '' },
        { title: '断电记忆', desc: '' },
      ],
      box4ListA: [
        { title: '独立电路', desc: '即使有故障，也不影响站点的其他端口充电' },
        { title: '安装方便', desc: '不用电工也能顺利安装' },
        { title: '质量更好', desc: '充电头不容易损坏' },
        { title: '简单无需选择', desc: '用户使用简单，清晰' },
        { title: '维护成本低', desc: '只需要单独换某个设备口' },
        { title: '价格有优势', desc: '同样数量的端口，价格更有优势' },
      ],
      box4ListB: [
        { title: '一体机', desc: '主机发生故障，全部充电端口无法使用' },
        { title: '安装复杂', desc: '需要专业电工，核对好线路安装' },
        { title: '易出故障', desc: '大功率输出，发热大，容易出故障' },
        { title: '复杂多端口选择', desc: '用户选择困难，多个端口选择会出错' },
        { title: '维护成本高', desc: '一换就是整机' },
        { title: '没价格优势', desc: '价格普通，没价格优势' },
      ],
      box5List: [
        {
          title: '广州大学城',
          dataPoints: [
            { name: '投入成本', num: '20,000', unit: '元'},
            { name: '辅设充电桩设备数', num: '20', unit: '套' },
            { name: '日均收入', num: '90', unit: '元', active: true },
            { name: '回本周期', num: '222', unit: '天' },
          ]
        },
        {
          title: '佛山陈村小区',
          dataPoints: [
            { name: '投入成本', num: '60,000', unit: '元'},
            { name: '辅设充电桩设备数', num: '57', unit: '套' },
            { name: '日均收入', num: '182.4', unit: '元', active: true },
            { name: '回本周期', num: '328', unit: '天' },
          ]
        },
        {
          title: '肇庆海伦堡',
          dataPoints: [
            { name: '投入成本', num: '26,000', unit: '元'},
            { name: '辅设充电桩设备数', num: '40', unit: '套' },
            { name: '日均收入', num: '96', unit: '元', active: true },
            { name: '回本周期', num: '270', unit: '天' },
          ]
        }
      ],
      box6List: [
        {
          title: '藏珑华府',
          dataPoints: [
            { name: '总投入', num: '6', unit: '万'},
            { name: '安装设备端口', num: '120', unit: '个' },
            { name: '每天收入', num: '200', unit: '元' }
          ]
        },
        {
          title: '翠苑小区',
          dataPoints: [
            { name: '总投入', num: '10', unit: '万'},
            { name: '安装设备端口', num: '150', unit: '个' },
            { name: '每天收入', num: '300', unit: '元' }
          ]
        },
        {
          title: '沙面公园',
          dataPoints: [
            { name: '总投入', num: '0.8', unit: '万'},
            { name: '安装设备端口', num: '24', unit: '个' },
            { name: '每天收入', num: '58', unit: '元' }
          ]
        }
      ],
      footerList: [
        { title: '协助维权', desc: '蒙受经济损失，可申请百度协助' },
        { title: '虚假赔偿', desc: '遇到品牌或资质冒用，可申请百度保障' },
        { title: '欺诈赔偿', desc: '遇到欺诈，经核查属实，可申请保障退还费用' },
      ],

      postForm: {
        phone: '',
        name: ''
      },
      btnDisable: false, // 按钮禁用状态
    }
  },

  methods: {
    onImageLoad() {
      this.imgSuccess = true
    },

    scrollToTop() {
      // 平滑滚动到页面顶部
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 可选参数, 表示平滑滚动
      });
    },

    sendEmail() {
      if (!this.postForm.name) return this.$toast('请输入姓名')
      if (!this.postForm.phone) return this.$toast('请输入电话')
      if (this.btnDisable) return this.$toast('提交太频繁，请5秒后再操作')

      this.btnDisable = true
      setTimeout(() => {
        this.btnDisable = false
      }, 5000)

      this.$request({
        url: '/consultInfo/add',
        data: this.postForm
      }).then(() => {
        this.$toast('提交成功')
      }).catch(() => {

      })
    },
  }
}
</script>

<style lang="scss" scoped>
@mixin sideBarCommonStyle() {
  background-color: #fff;
  .sidebar-item-icon {
    display: none;
  }
  .sidebar-item-icon-active {
    display: inline-block;
  }
  .sidebar-item-text {
    color: #04AC64;
  }
  .sidebar-item-popup {
    display: block;
  }
}
.full-page {
  width: 100%;
  .header {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    .logo {
      width: 209px;
      height: 62px;
    }
    .contact {
      display: flex;
      align-items: center;
      img {
        width: 26px;
        margin-right: 10px;
      }
      .text1 {
        color: #707070;
        font-size: 24px;
      }
      .text2 {
        margin-left: 12px;
        color: #04AC64;
        font-size: 28px;
        font-weight: 900;
      }
    }
  }
  .banner {
    margin-top: 62px;
    .banner-img {
      display: block;
      width: 100%;
      object-fit: contain;
    }
  }
  .box-1 {
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .box-1-title {
      text-align: center;
      .text-1 {
        color: #0C0D12;
        font-size: 48px;
      }
      .text-2 {
        color: #0C0D12;
        font-size: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .active {
          color: #04AC64;
          font-size: 60px;
          font-weight: bold;
          display: flex;
          align-items: center;
        }
      }
      .text-3 {
        font-size: 28px;
        color: #999999;
      }
    }
    .form {
      margin-top: 80px;
      display: flex;
      .form-item {
        margin-right: 10px;
        .form-item-label {
          font-size: 18px;
          color: #0C0D12;
        }
        .form-item-value {
          margin-top: 15px;
          width: 480px;
          height: 60px;
          background: #F5F5F5;
          input {
            width: 100%;
            height: 100%;
            font-size: 18px;
            border: none;
            outline: none;
            background-color: transparent;
            padding-left: 20px;
          }
        }
      }
    }
    .submit-btn {
      margin-top: 71px;
      width: 480px;
      height: 60px;
      background: #04AC64;
      border-radius: 10px;
      color: #fff;
      font-size: 28px;
      border: none;
      cursor: pointer;
      &:active {
      background-color: #3abd8d; /* 点击时的背景颜色 */
    }
    }
    .bottom-list {
      margin-top: 84px;
      width: 100%;
      display: flex;
      justify-content: center;
      .bottom-item {
        margin: 0 70px;
        text-align: center;
        img {
          display: block;
          width: 80px;
          height: 80px;
          margin: 0 auto 16px;
        }
        span {
          font-size: 18px;
          color: #666666;
        }
      }
    }
  }
  .box-2 {
    width: 100%;
    position: relative;
    .box-2-bg {
      width: 100%;
      height: 896px;
    }
    .box-2-content {
      width: 100%;
      padding: 118px 0;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
      .box-2-title {
        font-size: 48px;
        color: #fff;
      }
      .box-2-remark {
        margin-top: 28px;
        font-size: 24px;
        color: #fff;
      }
      .box-2-list {
        margin-top: 78px;
        display: flex;
        justify-content: center;
        .box-2-list-item {
          margin: 0 20px;
          width: 240px;
          height: 460px;
          border-radius: 16px;
          background:rgba(255, 255, 255, .9);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 120px;
            height: 120px;
          }
          .text1 {
            margin-top: 50px;
            font-weight: bold;
            font-size: 44px;
            color: #333333;
            display: flex;
            align-items: center;
            &.unit {
              font-size: 24px;
            }
          }
          .text2 {
            margin-top: 9px;
            font-size: 18px;
            color: #666;
          }
        }
      }
    }
  }
  .box-3 {
    // height: 880px;
    display: flex;
    .box-3-title {
      .title-1 {
        font-size: 40px;
        font-weight: bold;
        color: #04AC64;
      }
      .title-1 {
        font-size: 40px;
        color: #04AC64;
      }
      .title-2 {
        font-size: 36px;
        color: #333;
      }
    }
    .box-3-left {
      width: 50%;
      background: #fff;
      padding: 120px 0 100px 10%;
      .left-container {
        display: flex;
        flex-wrap: wrap;
        .container-item {
          width: 50%;
          margin-top: 56px;
          .container-item-title {
            display: flex;
            align-items: center;
            .title-line {
              width: 4px;
              height: 16px;
              background-color: #04AC64;
              margin-right: 8px;
            }
            .title-text {
              font-size: 20px;
              font-weight: bold;
              color: #04AC64;
            }
          }
          .container-item-desc {
            padding-right: 107px;
            margin-top: 16px;
            font-size: 16px;
            color: #666;
          }
        }
      }
    }
    .box-3-right {
      width: 50%;
      background: #F5F7FA;
      padding: 120px 0 100px 59px;
      .right-container {
        display: flex;
        flex-wrap: wrap;
        .container-item {
          width: 42%;
          margin-top: 76px;
          display: flex;
          align-items: center;
          .container-item-icon {
            width: 100px;
            height: 100px;
          }
          .container-item-content {
            margin-left: 24px;
            .container-item-title {
              font-size: 22px;
              font-weight: bold;
              color: #333333;
            }
            .container-item-desc {
              margin-top: 7px;
              padding-right: 20px;
              color: #666666;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .box-4 {
    width: 100%;
    height: 982px;
    background: url('../../assets/imgs/website/box-4-bg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .box-4-left {
      .box-4-left-img {
        display: block;
        width: 280px;
        height: 200px;
        margin-bottom: 24px;
      }
    }
    .box-4-right {
      margin-left: 31px;
      display: flex;
      align-items: center;
      position: relative;
      .right-box-1 {
        width: 540px;
        height: 740px;
        background: linear-gradient( 180deg, #56CC9A 0%, #04AC64 100%);
        border-radius: 24px;
        border: 3px solid #FFFFFF;
        .right-box-title {
          background: linear-gradient( 180deg, #FFFFFF 0%, #A7DDC7 100%);
        }
      }
      .right-box-2 {
        width: 540px;
        height: 700px;
        background: linear-gradient( 180deg, #F0F7FF 0%, #D7DFEA 100%);
        border-radius: 24px;
        border: 3px solid #FFFFFF;
        .container-item {
          border-bottom: 1px solid #CCCCCC !important; 
          .container-item-title {
            color: #333333 !important;
            font-size: 18px !important;
          }
          .container-item-desc {
            color: #666666 !important; 
            font-size: 14px !important;
          }
        }
      }
      .right-box-title {
        margin: 40px auto 60px;
        width: 246px;
        height: 60px;
        line-height: 60px;
        background: linear-gradient( 180deg, #FFFFFF 0%, #D9ECF0 100%);
        box-shadow: 0px 8px 20px 1px rgba(146,176,221,0.2);
        border-radius: 100px;
        border: 2px solid #FFFFFF;
        font-size: #333;
        font-size: 28px;
        text-align: center;
      }
      .vs-box {
        width: 240px;
        background: linear-gradient( 91deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.5) 52%, rgba(255,255,255,0) 100%);
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-54%,0);
        text-align: center;
        .vs-img {
          width: 116px;
        }
      }
      .right-box-container {
        padding: 0 50px;
        .container-item {
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(255, 255, 255, .3);
          padding: 16px 0;
          .container-item-icon {
            width: 30px;
            height: 30px;
            margin-right: 24px;
          }
          .container-item-content {
            .container-item-title {
              font-size: 20px;
              font-weight: bold;
              color: #fff;
            }
            .container-item-desc {
              margin-top: 8px;
              font-size: 16px;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .box-5 {
    padding: 91px 0;
    .box-5-title {
      font-size: 48px;
      color: #333333;
      text-align: center;
    }
    .box-5-container {
      margin-top: 77px;
      display: flex;
      justify-content: center;
      .box-5-left {
        .left-title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 40px;
        }
        .left-container {
          .left-content-item {
            padding: 24px 0 24px 24px;
            width: 720px;
            background: linear-gradient( 90deg, #F5F7F6 0%, #F8FDF8 100%);
            border-radius: 8px;
            margin-bottom: 8px;
            .item-title {
              font-size: 20px;
              font-weight: bold;
              color: #333333;
              margin-bottom: 24px;
            }
            .data-point {
              display: flex;
              justify-content: space-between;
              .data-point-item {
                flex: 1;
                .data-point-value {
                  &.active {
                    color: #04AC64;
                  }
                  .num {
                    font-size: 24px;
                    font-weight: bold;
                  }
                  .unit {
                    margin-left: 8px;
                    font-size: 14px;
                  }
                }
                .data-point-name {
                  margin-top: 8px;
                  font-size: 14px;
                  color: #666;
                }
              }
            }
          }
        }
      }
      .box-5-right {
        margin-left: 54px;
        img {
          width: 626px;
        }
      }
    }
  }
  .box-6 {
    padding: 91px 0;
    background-color: #F7FFFC;
    text-align: center;
    .box-6-title {
      font-size: 48px;
    }
    .box-6-img {
      margin-top: 117px;
      width: 1200px;
    }
  }
  .box-7 {
    padding: 91px 0;
    text-align: center;
    .box-7-title {
      font-size: 48px;
      margin-bottom: 57px;
    }
    .box-7-list {
      display: flex;
      justify-content: center;
      .box-7-item {
        width: 445px;
        margin: 0 15px;
        .box-7-item-img {
          width: 100%;
        }
        .box-7-item-name {
          height: 100px;
          line-height: 100px;
          text-align: center;
          background: linear-gradient( 180deg, rgba(4,172,100,0) 0%, #04AC64 100%);
          font-weight: bold;
          font-size: 24px;
          color: #fff;
        }
        .box-7-item-point {
          margin-top: 46px;
          display: flex;
          justify-content: center;
          .box-7-item-point-item {
            flex: 1;
            .point-item-name {
              font-size: 16px;
              color: #666;
            }
            .point-item-value {
              margin-top: 8px;
              .num {
                font-size: 24px;
                font-weight: bold;
                color: #333;
              }
              .unit {
                font-size: 14px;
                font-weight: bold;
                color: #333;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    height: 230px;
    background-color: #F5F5F5;
    .footer-top {
      padding: 56px 0;
      display: flex;
      justify-content: center;
      .footer-item {
        margin-right: 80px;
        display: flex;
        align-items: center;
        .footer-item-icon {
          width: 40px;
        }
        .footer-item-content {
          margin-left: 16px;
          .footer-item-title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
          .footer-item-desc {
            margin-top: 8px;
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }
    .footer-bottom {
      font-size: 14px;
      color: #666666;
      text-align: center;
      .footer-bottom-text2 {
        margin-top: 8px;
      }
    }
  }
  .sidebar {
    position: fixed;
    right: 0;
    top: 30%;
    .sidebar-item {
      position: relative;
      margin-top: 4px;
      padding: 12px 16px;
      background-color: #04AC64;
      text-align: center;
      cursor: pointer;
      &.wechat:hover {
        @include sideBarCommonStyle();
      }
      &.phone:hover {
        @include sideBarCommonStyle();
      }
      &.about-us:hover {
        @include sideBarCommonStyle();
      }
      .sidebar-item-icon {
        width: 32px;
      }
      .sidebar-item-icon-active {
        display: none;
        width: 32px;
      }
      .sidebar-item-text {
        margin-top: 6px;
        color: #fff;
        font-size: 12px;
      }
      .sidebar-item-popup {
        display: none;
        position: absolute;
        top: 0;
        left: -370px;
        .qrcode {
          width: 350px;
          height: 350px;
        }
        .item-popup-container {
          padding-top: 26px;
          width: 100%;
          height: 175px;
          background-color: #F8F8F8;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 9;
          .item-popup-number {
            font-size: 42px;
            color: #04AC64;
          }
          .item-popup-text {
            margin-top: 6px;
            font-size: 36px;
            color: #04AC64;
          }
        }
      }
    }
  }
}
</style>